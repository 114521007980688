import { Control, RegisterOptions, useController } from 'react-hook-form';
import {
  Box,
  HStack,
  RadioProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';

type RadioCheckboxGroupProps = {
  control: Control<any>;
  name: string;
  options: string[];
  rules?: RegisterOptions;
};

const RadioCheckboxGroup = ({
  control,
  name,
  options,
  rules,
}: RadioCheckboxGroupProps) => {
  const { field } = useController({
    control,
    name,
    rules,
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: field.value,
  });

  const group = getRootProps();

  return (
    <HStack spacing={4} {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCheckbox key={value} {...radio}>
            {value}
          </RadioCheckbox>
        );
      })}
    </HStack>
  );
};

function RadioCheckbox(props: RadioProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        background="ui.white"
        h={6}
        w={6}
        _checked={{
          color: 'ui.black.bg',
          bgImage:
            'data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10.4549%200.454932C10.6648%200.24684%2010.9482%200.129726%2011.2438%200.128911C11.5394%200.128095%2011.8234%200.243643%2012.0345%200.450574C12.2455%200.657505%2012.3667%200.939192%2012.3717%201.23473C12.3767%201.53028%2012.2653%201.81593%2012.0614%202.02993L6.07337%209.51493C5.97046%209.62578%205.84625%209.71474%205.70817%209.77648C5.57009%209.83823%205.42098%209.8715%205.26975%209.8743C5.11852%209.8771%204.96828%209.84937%204.82801%209.79278C4.68774%209.73619%204.56032%209.6519%204.45337%209.54493L0.48587%205.57593C0.37534%205.47294%200.286687%205.34874%200.225199%205.21074C0.163711%205.07274%200.130648%204.92377%200.127983%204.77272C0.125318%204.62166%200.153105%204.47162%200.209686%204.33154C0.266268%204.19145%200.350485%204.0642%200.457313%203.95738C0.564141%203.85055%200.691392%203.76633%200.831474%203.70975C0.971556%203.65317%201.1216%203.62538%201.27265%203.62805C1.42371%203.63071%201.57268%203.66377%201.71068%203.72526C1.84868%203.78675%201.97288%203.8754%202.07587%203.98593L5.21687%207.12543L10.4264%200.487933C10.4357%200.476313%2010.4457%200.465291%2010.4564%200.454932H10.4549Z%22%20fill%3D%22%232D2C2D%22%2F%3E%0A%3C%2Fsvg%3E%0A',
          bgRepeat: 'no-repeat',
          bgPosition: 'center',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
      />
    </Box>
  );
}

export default RadioCheckboxGroup;
