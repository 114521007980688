import { forwardRef, Text as ChakraText } from '@chakra-ui/react';

// Modular Scale (ms) values
// See design system for more details: https://www.figma.com/file/tqBECs5tvHOhOQUytc6mDE/Design-System?node-id=1%3A147k
//                  -1,   0,    1,    2,    3,     4,     5,
const fontSizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];
const lineHeights = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];

// ms max is 4 for non-marketing text
const htmlElements = ['span', 'span', 'h4', 'h3', 'h2', 'h1'];
const marketingHtmlElements = ['span', 'span', 'span', 'h4', 'h3', 'h2', 'h1'];

const Text = forwardRef(
  (
    {
      ms = 0,
      children,
      bold = false,
      marketing = false,
      asEl = '',
      color = 'ui.black.medium',
      breakWord = false,
      block = false,
      ...props
    },
    ref,
  ) => {
    const fontSize = fontSizes[ms + 1]; // add 1 since the scale starts at -1
    const lineHeight = lineHeights[ms + 1]; // add 1 since the scale starts at -1
    const fontWeight = bold ? 'bold' : 'normal';
    const htmlElement =
      asEl ||
      (marketing ? marketingHtmlElements[ms + 1] : htmlElements[ms + 1]);

    const breakWordStyle: unknown = breakWord
      ? {
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        }
      : {};

    return (
      <ChakraText
        ref={ref}
        as={htmlElement}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        color={color}
        letterSpacing="normal"
        display={block ? 'block' : 'initial'}
        {...props}
        {...breakWordStyle}
      >
        {children}
      </ChakraText>
    );
  },
);

export default Text;
