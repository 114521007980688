export const USER_ROLE = {
  SUPERADMIN: 'SUPERADMIN',
  VENDOR_ADMIN: 'VENDOR_ADMIN',
  VENDOR_USER: 'VENDOR_USER',
  AGENCY_ADMIN: 'AGENCY_ADMIN',
  AGENCY_USER: 'AGENCY_USER',
  CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
  CUSTOMER_USER: 'CUSTOMER_USER',
};

export const CUSTOMER_EMAIL = 'CUSTOMER_EMAIL';

export const ALL_USER_ROLES = [
  USER_ROLE.SUPERADMIN,
  USER_ROLE.VENDOR_ADMIN,
  USER_ROLE.VENDOR_USER,
  USER_ROLE.AGENCY_ADMIN,
  USER_ROLE.AGENCY_USER,
  USER_ROLE.CUSTOMER_ADMIN,
  USER_ROLE.CUSTOMER_USER,
] as const;
export type USER_ROLES = typeof ALL_USER_ROLES[number];
