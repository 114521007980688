import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

type LinkProps = NextLinkProps & Omit<ChakraLinkProps, 'href' | 'as'>;

function Link({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref = true,
  prefetch,
  locale,
  children,
  ...props
}: LinkProps) {
  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <ChakraLink
        color="ui.blue.primary"
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
        {...props}
      >
        {children}
      </ChakraLink>
    </NextLink>
  );
}

export default Link;
