import { FormLabel as ChakraFormLabel } from '@chakra-ui/react';

const defaultStyles = {
  color: 'ui.black.medium',
  fontSize: 'xs',
  lineHeight: 'xs',
  fontWeight: 'bold',
  mb: '2px',
};

const FormLabel = (props) => {
  return <ChakraFormLabel {...defaultStyles} {...props} />;
};

export default FormLabel;
