import { ReactNode } from 'react';
import { BsFillCaretDownFill, BsFillCaretRightFill } from 'react-icons/bs';
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps as ChakraAccordionProps,
  Box,
  chakra,
} from '@chakra-ui/react';
import { Text } from '@scope';

const CaretRight = chakra(BsFillCaretRightFill);
const CaretDown = chakra(BsFillCaretDownFill);

type Section = {
  id?: string;
  label: string;
  description?: string;
  content: ReactNode | string;
  actionButton?: ReactNode;
};

type AccordionProps = ChakraAccordionProps & {
  sections: Section[];
};

function Accordion({ sections, ...props }: AccordionProps) {
  return (
    <ChakraAccordion allowToggle {...props}>
      {sections.map(({ id, label, description, content, actionButton }) => (
        <AccordionItem
          data-id={id}
          key={label}
          borderTop="0"
          borderBottom="1px"
          borderBottomColor="ui.gray.light"
          _last={{
            borderBottom: '0',
          }}
        >
          {({ isExpanded }) => (
            <>
              <Text
                ms={0}
                as="h2"
                color={isExpanded ? 'ui.black.dark' : 'ui.gray.dark'}
              >
                <Box display={{ sm: 'none' }}>{actionButton}</Box>

                <AccordionButton
                  py={{ base: '4', sm: '6' }}
                  px={{ base: '0', sm: '6' }}
                  experimental_spaceX="4"
                  alignItems="flex-start"
                  as="div"
                  cursor="pointer"
                >
                  {isExpanded ? (
                    <CaretDown
                      color="ui.gray.light"
                      size="16px"
                      minW="16px"
                      mt="1"
                    />
                  ) : (
                    <CaretRight
                      color="ui.gray.light"
                      size="16px"
                      minW="16px"
                      mt="1"
                    />
                  )}

                  <Box textAlign="left" experimental_spaceY="1" w="100%">
                    <span>{label}</span>

                    {description && (
                      <Text ms={-1} color="ui.gray.dark" block>
                        {description}
                      </Text>
                    )}
                  </Box>

                  <Box display={{ base: 'none', sm: 'initial' }}>
                    {actionButton}
                  </Box>
                </AccordionButton>
              </Text>

              <AccordionPanel py="6" px={{ base: '0', sm: '6' }}>
                {content}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
}

export default Accordion;
