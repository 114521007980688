import { ReactNode } from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

type ItemRadio = UseRadioProps & {
  children: ReactNode;
};

const ItemRadio = (props: ItemRadio) => {
  const { children } = props;

  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" width="100%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="ui.gray.light"
        width="100%"
        color="ui.gray.dark"
        fontSize="sm"
        lineHeight="sm"
        userSelect="none"
        variant="outline"
        _checked={{
          bg: 'white',
          color: 'primary.600',
          borderColor: 'primary.600',
          fontWeight: 'bold',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          borderColor: 'ui.blue.primary',
          color: 'ui.blue.primary',
          fontWeight: 'bold',
        }}
        p={2}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ItemRadio;
