import { UserRole } from 'types';

export const isAgencyAdmin = (role: UserRole) => {
  return role === UserRole.AGENCY_ADMIN;
};

export const isAgencyUser = (role: UserRole) => {
  return role === UserRole.AGENCY_USER;
};

export const isAgency = (role: UserRole) => {
  return isAgencyAdmin(role) || isAgencyUser(role);
};

export const isCustomerAdmin = (role: UserRole) => {
  return role === UserRole.CUSTOMER_ADMIN;
};

export const isCustomerUser = (role: UserRole) => {
  return role === UserRole.CUSTOMER_USER;
};

export const isCustomer = (role: UserRole) => {
  return isCustomerAdmin(role) || isCustomerUser(role);
};

export const isVendorAdmin = (role: UserRole) => {
  return role === UserRole.VENDOR_ADMIN;
};

export const isVendorUser = (role: UserRole) => {
  return role === UserRole.VENDOR_USER;
};

export const isVendor = (role: UserRole) => {
  return isVendorAdmin(role) || isVendorUser(role);
};

export const getUserFullName = (user) => {
  if (!user.firstName || !user.lastName) return user.email;
  return `${user.firstName} ${user.lastName}`;
};

export const splitName = (fullName: string) => {
  const nameArray = fullName.split(' ');
  const firstName = nameArray.shift();
  const lastName = nameArray.join(' ');
  return { firstName, lastName };
};

export const getUserGroup = (user) => {
  if (isAgency(user.role)) {
    return user.agency?.name;
  }

  if (isCustomer(user.role)) {
    return user.customerGroup?.name;
  }

  if (isVendor(user.role)) {
    return user.vendorProfile?.vendor?.name;
  }

  return '';
};

export const getBusinessProfileLink = (user) => {
  if (isAgency(user.role)) {
    return `/agency/${user.agency?.slug}`;
  }

  if (isCustomer(user.role)) {
    return `/business/${user.customerGroup?.slug}`;
  }

  if (isVendor(user.role)) {
    return `/vendor/${user.vendorProfile?.vendor?.slug}`;
  }

  return '/';
};

export const getAdorableAvatarByUserId = (userId: string, size = 40) => {
  return `https://avatars.dicebear.com/api/bottts/${userId}.svg?options[width]=${size}&options[height]=${size}`;
};

export const getUserAvatar = (user: any, size?: number) => {
  const { id, role } = user;

  let userAvatar;

  if (isVendor(role) && user.vendorProfile?.vendor?.profile?.logo) {
    userAvatar = user.vendorProfile.vendor.profile.logo;
  } else if (isAgency(role) && user.expertProfile?.avatar) {
    userAvatar = user.expertProfile.avatar;
  } else if (isCustomer(role) && user.customerProfile?.avatar) {
    userAvatar = user.customerProfile.avatar;
  }

  return userAvatar; // || getAdorableAvatarByUserId(id, size);
};
