import { Flex } from '@chakra-ui/react';

import Text from 'components/shared/Text';

type FilteredItemsType = {
  totalItems: number;
  totalFilteredItems: number;
  resetFilters: (stages) => void;
};

const FilteredItems = ({
  totalItems,
  totalFilteredItems,
  resetFilters,
}: FilteredItemsType) => {
  const itemsNumber = totalItems - totalFilteredItems;

  return (
    itemsNumber > 0 && (
      <Flex justifyContent="center" p="4">
        <Text>
          {itemsNumber} projects filtered from view.{' '}
          <Text
            ms={0}
            bold
            color="ui.blue.primary"
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
            onClick={resetFilters}
          >
            Show all
          </Text>
        </Text>
      </Flex>
    )
  );
};

export default FilteredItems;
