import { ReactNode, useEffect } from 'react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  useTheme,
} from '@chakra-ui/react';
import { Spacer, Text } from '@scope';
import { rgba } from 'polished';

import useMobile from 'libs/useMobile';
import useResponsive from 'libs/useResponsive';
import { mediaQuery } from 'styles/breakpoints';

type ModalProps = ChakraModalProps & {
  children: ReactNode;
  title?: string | ReactNode;
  promptOnTabClose?: boolean;
  footer: ReactNode;
  contentHeight?: string;
  modalStyles?;
  useFooterSpacer?: boolean;
};

function Modal({
  children,
  title,
  size = 'lg',
  onClose,
  isOpen,
  closeOnOverlayClick = false,
  closeOnEsc = false,
  promptOnTabClose = false,
  footer,
  contentHeight,
  modalStyles,
  useFooterSpacer = true,
  ...props
}: ModalProps) {
  const { colors } = useTheme();

  const isMd = useResponsive(mediaQuery.max.md);

  useEffect(() => {
    if (promptOnTabClose) {
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [promptOnTabClose]);

  const _onClose = () => {
    window.onbeforeunload = null;
    onClose();
  };

  return (
    <ChakraModal
      autoFocus={false}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      isCentered
      isOpen={isOpen}
      onClose={_onClose}
      scrollBehavior="inside"
      size={isMd ? 'full' : size}
      {...props}
    >
      <ModalOverlay bg={rgba(colors.ui.black.medium, 0.3)} />

      <ModalContent
        border="1px solid #DBE3E6"
        borderRadius={5}
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.2)"
        m={0}
        p="8"
        textAlign="center"
        height={contentHeight}
        {...modalStyles}
      >
        {title && (
          <ModalHeader p="0" mb="1">
            <Text ms={2} bold>
              {title}
            </Text>
          </ModalHeader>
        )}

        <ModalBody p="0">{children}</ModalBody>

        {footer && (
          <>
            {useFooterSpacer ? <Spacer h={8} /> : <></>}

            <ModalFooter p="0px" experimental_spaceX="2">
              {footer}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
