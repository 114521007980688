import { useCallback, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { BsPlusCircleFill } from 'react-icons/bs';
import { VscDeviceCamera } from 'react-icons/vsc';
import { Box, chakra, Flex } from '@chakra-ui/react';
import Spacer from '@scope/Spacer';
import Text from '@scope/Text';

type ImageDropzoneProps = {
  onChange?: (event) => void;
  setImage?: (files: File[] | File) => void;
  uploadedImage?: string;
};

const Camera = chakra(VscDeviceCamera);
const Plus = chakra(BsPlusCircleFill);

function ImageDropzone({
  setImage,
  onChange,
  uploadedImage,
}: ImageDropzoneProps) {
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);

      if (setImage) {
        setImage(acceptedFiles[0]);
      }
    },
    [setImage],
  );

  const handleDelete = () => {
    setFile(null);

    // need to run useEffect on deletion
    setIsDeleting(true);

    if (onChange || isDeleting) {
      // Remove file from React Hook Form.
      onChange({ target: { files: [] } });
    }

    if (setImage) {
      setImage(null);
    }
  };

  useEffect(() => {
    if (uploadedImage) setFile(uploadedImage);
  }, [uploadedImage, isDeleting]);

  const image = file;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    accept: 'image/jpeg, image/png',
    disabled: !!image,
  });

  return (
    <Box>
      <Flex
        {...getRootProps()}
        justify="center"
        textAlign="center"
        userSelect="none"
        cursor="pointer"
        w="120px"
        h="120px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="#DBE3E6"
        borderRadius="full"
      >
        <input
          {...getInputProps({
            onChange,
          })}
        />

        {image ? (
          <AvatarEditor
            image={image}
            width={118}
            height={118}
            borderRadius={100}
            style={{ marginTop: '-26px' }}
            color={[255, 255, 255]}
          />
        ) : (
          <Flex direction="column" align="center" justify="center">
            <Box position="relative" ml="2">
              <Camera color="ui.black.dark" w="6" h="6" />
              <Plus
                color="ui.blue.secondary"
                w="4"
                h="4"
                position="absolute"
                top="10px"
                left="-10px"
              />
            </Box>

            <Text ms={0}>
              Add
              <br />
              profile pic
            </Text>
          </Flex>
        )}
      </Flex>

      {image && (
        <>
          <Text
            ms={0}
            bold
            color="ui.blue.secondary"
            width="fit-content"
            cursor="pointer"
            position="absolute"
            onClick={handleDelete}
          >
            Delete photo
          </Text>

          <Spacer h={4} />
        </>
      )}
    </Box>
  );
}

export default ImageDropzone;
