import { DateUtils } from 'react-day-picker';
import {
  addMilliseconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  isBefore,
  isEqual,
  parse,
} from 'date-fns';

import { isDev } from 'config/env';

const DATE_INPUT_FORMAT = 'MMMM dd, yyyy';

export const formatFullDate = (unixTime) => {
  if (unixTime) {
    return format(new Date(unixTime), DATE_INPUT_FORMAT);
  }

  return undefined;
};

export const formatDateInput = (date, _?, locale?) => {
  if (!date) {
    return '';
  }

  return format(date, DATE_INPUT_FORMAT, { locale });
};

export const parseDateInput = (str, _?, locale?) => {
  const parsed = parse(str, DATE_INPUT_FORMAT, new Date(), { locale });

  if (DateUtils.isDate(parsed)) {
    return parsed;
  }

  return undefined;
};

export const isStartDateBeforeEndDate = (startDate, endDate) => {
  const start = parseDateInput(startDate);
  const end = parseDateInput(endDate);

  return isBefore(start, end) || isEqual(start, end);
};

export const timestampToDateAndTime = (
  date,
  timeFormat = 'MMM. d, y h:mm aaa',
) => {
  return format(new Date(date), timeFormat);
};

export const formatExpireTime = (ts: number | Date, limit: number) => {
  const timestamp = new Date(ts);

  const days = Math.max(
    differenceInDays(addMilliseconds(timestamp, limit), new Date()),
    0,
  );

  if (days > 0) {
    return `${days} day${days === 1 ? '' : 's'}`;
  }

  const hours = Math.max(
    differenceInHours(addMilliseconds(timestamp, limit), new Date()),
    0,
  );

  if (hours > 0) {
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  }

  const minutes = Math.max(
    differenceInMinutes(addMilliseconds(timestamp, limit), new Date()),
    0,
  );

  if (minutes > 0) {
    return `${minutes} minute${minutes === 1 ? '' : 's'}`;
  }

  return 'less than a minute';
};

export const getStartOfDay = (_date: Date) => {
  const date = new Date(_date);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const getDateInUserTimezone = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
