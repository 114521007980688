import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  forwardRef,
} from '@chakra-ui/react';
import { UserRole } from 'types';

import { isAgency, isCustomer, isVendor } from 'utils/user';

type AvatarProps = ChakraAvatarProps & {
  size: string | number;
  role?: UserRole;
  borderWidth?: string | number;
};

const getBorderColor = (role: UserRole) => {
  if (!role) {
    return 'ui.gray.light';
  }

  if (isAgency(role)) {
    return 'ui.expert';
  }

  if (isCustomer(role)) {
    return 'ui.customer';
  }

  if (isVendor(role)) {
    return 'ui.vendor';
  }

  return 'ui.gray.light';
};

const getFontSize = (size: number) => {
  if (size < 8) return 'calc(3rem / 3.5)';
  if (size === 8) return 'calc(3rem / 3)';
  if (size > 16) return 'calc(3rem / 1.5)';
  return 'calc(3rem / 2.5)';
};

const Avatar = forwardRef<AvatarProps, 'span'>(
  ({ name, src, size, role, borderWidth, ...props }, ref) => {
    const borderColor = getBorderColor(role);
    const fontSize = getFontSize(+size);

    return (
      <ChakraAvatar
        name={name}
        src={src}
        h={size}
        w={size}
        bg="ui.white"
        color="ui.black.medium"
        showBorder
        borderWidth={borderWidth || '2px'}
        borderStyle="solid"
        borderColor={borderColor}
        userSelect="none"
        sx={{
          'div[role="img"]': {
            userSelect: 'none',
            fontSize,
          },
        }}
        ref={ref}
        {...props}
      />
    );
  },
);

export default Avatar;
