import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import { mediaQuery } from 'styles/breakpoints';

const useResponsive = (size = mediaQuery.max.sm) => {
  const [isClient, setIsClient] = useState(false);

  const [isSmaller] = useMediaQuery(size);

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return isClient ? isSmaller : false;
};

export default useResponsive;
