import { useFormContext } from 'react-hook-form';
import { Box, chakra, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Checkbox, Input, Text } from '@scope';
import Spacer from '@scope/Spacer';

import useCurrentUser from 'libs/useCurrentUser';
import { getUserFullName } from 'utils/user';

function SignInput() {
  const { user } = useCurrentUser();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <FormControl id="sign" isInvalid={!!errors.sign}>
        <Input
          placeholder="Type full name"
          {...register('sign', {
            required: 'Your sign is required',
          })}
        />
        <Text ms={-1} color="ui.gray.dark">
          Your name: {getUserFullName(user)}
        </Text>

        <FormErrorMessage>
          {errors.sign && errors.sign.message}
        </FormErrorMessage>
      </FormControl>

      <Spacer h={8} />

      <FormControl id="serviceAgreement" isInvalid={!!errors.serviceAgreement}>
        <Checkbox
          isInvalid={!!errors.serviceAgreement}
          {...register('serviceAgreement', {
            required: true,
          })}
        >
          <Text ms={-1}>
            I understand that by accepting this contract, I’m agreeing to
            Scope’s{' '}
            <chakra.a
              href="https://www.scopeinc.com/msa"
              target="_blank"
              rel="noopener noreferrer nofollow"
              color="ui.blue.primary"
              fontWeight="bold"
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
            >
              Services Agreement
            </chakra.a>
            .
          </Text>
        </Checkbox>

        <FormErrorMessage>
          {errors.serviceAgreement && errors.serviceAgreement.message}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}

export default SignInput;
