import {
  forwardRef,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

import GetRemainingChars from 'components/shared/RemainingChars';

const defaultStyles = {
  bg: 'ui.white',
  color: 'ui.black.medium',
  lineHeight: 'sm',
  fontWeight: 'normal',
  fontSize: 'sm',
  borderRadius: '5px',
  _placeholder: { color: 'ui.gray.medium' },
  p: '2',
};

type InputProps = ChakraInputProps & {
  remainingChars?: {
    maxCharLength: number;
    input: any;
  };
};

const Input = forwardRef(({ remainingChars, ...props }: InputProps, ref) => {
  return (
    <>
      <ChakraInput ref={ref} {...defaultStyles} {...props} />

      {remainingChars && remainingChars.input && (
        <GetRemainingChars
          maxCharLength={remainingChars.maxCharLength}
          inputLength={remainingChars.input.length}
        />
      )}
    </>
  );
});

// Ref: https://github.com/chakra-ui/chakra-ui/issues/2269
Input.id = 'Input';

export default Input;
