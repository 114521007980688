import { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import {
  chakra,
  forwardRef,
  IconButton,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

const Eye = chakra(BsEye);
const EyeSlash = chakra(BsEyeSlash);

const defaultStyles = {
  bg: 'ui.gray.bg',
  color: 'ui.black.medium',
  lineHeight: 'sm',
  fontWeight: 'normal',
  fontSize: 'sm',
  borderRadius: '5px',
  _placeholder: { color: 'ui.gray.dark' },
};

const PasswordInput = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <ChakraInput
        type={show ? 'text' : 'password'}
        ref={ref}
        {...defaultStyles}
        {...props}
      />
      <InputRightElement>
        <IconButton
          colorScheme="gray"
          size="sm"
          aria-label="Show Password"
          icon={show ? <EyeSlash /> : <Eye />}
          onClick={handleClick}
        />
      </InputRightElement>
    </InputGroup>
  );
});

// Ref: https://github.com/chakra-ui/chakra-ui/issues/2269
PasswordInput.id = 'PasswordInput';

export default PasswordInput;
