export enum UserRole {
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  AGENCY_USER = 'AGENCY_USER',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  CUSTOMER_USER = 'CUSTOMER_USER',
  VENDOR_ADMIN = 'VENDOR_ADMIN',
  VENDOR_USER = 'VENDOR_USER',
}

export type Deliverable = {
  name: string;
  content: string;
  hours: number;
  orderIndex?: number;
};

export type ProposalProps = {
  hasAddedPaymentMethod?: boolean;
  proposal: {
    id: string;
    timeframe: string;
    cost: string;
    other: string;
    deliverables: Deliverable[];
    status: string;
  };
};

export type ContractType = {
  id: string;
  projectId: string;
  status: string;
  startDate: string;
  timeframe: string;
  cost: number;
  deliverables: Deliverable[];
  statementOfWork: string;
};

export type VerifyAgencyType = {
  agencyId: string;
  certifications: string[];
};

export type AgencyToAgencyTag = {
  agencyId: string;
  vendorId: string;
  agencyTagId: string;
  agencyTag?: any;
};

export type AgencyTag = {
  id: number;
  name: string;
  verified: boolean;
  orderIndex: number;
};

export enum VendorResourceCategoryEnum {
  TRAINING = 'TRAINING',
  PRODUCT_UPDATE = 'PRODUCT_UPDATE',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export enum VendorResourceTypeEnum {
  FILE = 'FILE',
  TEXT = 'TEXT',
  URL = 'URL',
}

export type VendorResource = {
  id: string;
  name: string;
  category: VendorResourceCategoryEnum;
  type: VendorResourceTypeEnum;
  file?: string;
  fileName?: string;
  text?: string;
  url?: string;
  certificationId?: string;
  certification?: any;
  createdAt: string;
  updatedAt: string;
};
