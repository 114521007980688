import { BsChevronRight } from 'react-icons/bs';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
} from '@chakra-ui/react';
import { Link } from '@scope';

const ChevronRight = chakra(BsChevronRight);

type CustomBreadcrumbType = {
  prevPageLabel: string;
  prevPageHref: string;
  label: string;
};

const CustomBreadcrumb = ({
  prevPageLabel,
  prevPageHref,
  label,
}: CustomBreadcrumbType) => {
  return (
    <Breadcrumb
      separator={
        <ChevronRight
          color="ui.gray.medium"
          height="12.8px"
          strokeWidth="2px"
        />
      }
      mb="4"
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          color="ui.blue.primary"
          fontSize="xs"
          href={prevPageHref}
        >
          {prevPageLabel}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink as={Link} color="ui.gray.medium" fontSize="xs" href="#">
          {label}
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
