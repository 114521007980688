import {
  forwardRef,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react';

const defaultStyles: SelectProps = {
  bg: 'ui.white',
  color: 'ui.black.dark',
  lineHeight: 'sm',
  fontWeight: 'normal',
  fontSize: 'sm',
  borderRadius: '5px',
  _placeholder: { color: 'ui.gray.dark' },
  sx: {
    paddingInline: '2',
    '&:invalid': {
      color: 'ui.gray.medium',
    },
  },
};

const Select = forwardRef(({ children, ...props }: SelectProps, ref) => {
  return (
    <ChakraSelect ref={ref} {...defaultStyles} {...props}>
      {children}
    </ChakraSelect>
  );
});

// Ref: https://github.com/chakra-ui/chakra-ui/issues/2269
Select.id = 'Select';

export default Select;
