import {
  forwardRef,
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from '@chakra-ui/react';
import { Text } from '@scope';

type RadioProps = ChakraRadioProps & {
  //
};

const defaultStyles: ChakraRadioProps = {
  colorScheme: 'primary',
};

const Radio = forwardRef<RadioProps, 'label'>(({ children, ...props }, ref) => {
  return (
    <ChakraRadio ref={ref} {...defaultStyles} {...props}>
      <Text ms={0} color="ui.gray.dark">
        {children}
      </Text>
    </ChakraRadio>
  );
});

export default Radio;
