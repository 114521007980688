import API from 'config/axios';

const renameProject = async (projectId: string, name: string) => {
  try {
    const { data } = await API.patch(`/project/${projectId}/rename`, {
      name,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const archive = async (
  projectId: string,
  projectData: { stage: string; substage: string },
) => {
  try {
    const { data } = await API.post(
      `/project/${projectId}/archive`,
      projectData,
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const unarchive = async (projectId: string) => {
  try {
    const { data } = await API.post(`/project/${projectId}/unarchive`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const changeStage = async (
  projectId: string,
  projectData: { stage: string },
) => {
  try {
    const { data } = await API.post(`/project/${projectId}/stage`, projectData);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const uploadFiles = async (projectId, formData) => {
  try {
    const { data } = await API.post(
      `/project/attachments/${projectId}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const downloadFile = async (fileId) => {
  try {
    const { data } = await API({
      url: `/project/attachments/${fileId}/download`,
      method: 'GET',
      responseType: 'blob',
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const deleteFile = async (fileId) => {
  try {
    const { data } = await API.delete(`/project/attachments/${fileId}`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const createProject = async (projectData) => {
  try {
    const { data } = await API.post(`/project`, {
      ...projectData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const approveProject = async (projectId: string) => {
  try {
    const { data } = await API.post(`/project/${projectId}/approve`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
const getAttachments = async (projectId) => {
  try {
    const { data } = await API.get(`/project/${projectId}/attachments`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const addStakeholder = async (
  projectId: string,
  stakeholderData: { userIds: number[]; emitNewMembers?: boolean },
) => {
  try {
    const { data } = await API.post(
      `/project/${projectId}/stakeholders`,
      stakeholderData,
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const removeStakeholder = async (
  projectId: string,
  stakeholderData: { userId: number; emitNewMembers?: boolean },
) => {
  try {
    const { data } = await API.delete(`/project/${projectId}/stakeholders`, {
      data: stakeholderData,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getMatchedExperts = async (projectId: string) => {
  try {
    const { data } = await API.get(`/project/${projectId}/matched`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const updateProject = async (projectId, _data) => {
  try {
    const { data } = await API.patch(`/project/${projectId}`, _data);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const newButtonClicked = async () => {
  try {
    const { data } = await API.post('/project/new-clicked');

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getSchedulingLink = async (projectId: string) => {
  try {
    const { data } = await API.get(`/project/${projectId}/scheduling-link`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const meetingClicked = async (projectId: string, meetingId: string) => {
  try {
    const { data } = await API.post(`/project/${projectId}/meeting-clicked`, {
      meetingId,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const downloadContract = async (projectId: string) => {
  try {
    const { data } = await API.get(`/project/${projectId}/download-contract`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      withCredentials: true,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const projectApi = {
  archive,
  unarchive,
  changeStage,
  renameProject,
  uploadFiles,
  downloadFile,
  deleteFile,
  createProject,
  approveProject,
  getAttachments,
  addStakeholder,
  removeStakeholder,
  getMatchedExperts,
  updateProject,
  newButtonClicked,
  getSchedulingLink,
  meetingClicked,
  downloadContract,
};

export default projectApi;
