import { toast } from '@scope';
import axios from 'axios';
import cookies from 'js-cookie';

import env, { isDev } from './env';

const baseURL = `${env.API_URL}/v1`;
const API = axios.create({ baseURL });

let isRefreshing = false;
let logoutTimeout = null;
let requestQueue = [];

// Add access token to each request
API.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${cookies.get('at')}`;

  return config;
});

const callRequestsFromQueue = (rt) => requestQueue.map((cb) => cb(rt));
const addRequestToQueue = (req) => requestQueue.push(req);

const requestNewToken = async () => {
  const refreshToken = cookies.get('rt');
  const {
    data: { accessToken },
  } = await API.post('/auth/refresh', { refreshToken });
  return accessToken;
};

const logoutFn = () =>
  setTimeout(() => {
    cookies.remove('at', { path: '/' });
    cookies.remove('rt', { path: '/' });
    logoutTimeout = null;

    window.location.reload();
  }, 2000);

API.interceptors.response.use(null, (err) => {
  const {
    config,
    response: { status, data },
  } = err;

  const originalRequest = config;

  if (
    status === 401 &&
    data?.message !== 'Token is invalid.' &&
    data?.message !== 'Email or password is incorrect.' &&
    !logoutTimeout
  ) {
    if (!isRefreshing) {
      isRefreshing = true;

      requestNewToken()
        .then((accessToken) => {
          cookies.set('at', accessToken, {
            path: '/',
            expires: 30, // 30 days
            secure: !isDev,
          });

          isRefreshing = false;
          callRequestsFromQueue(accessToken);
          requestQueue = [];
        })
        .catch(() => {
          logoutTimeout = logoutFn();
        });
    }

    return new Promise((resolve) => {
      addRequestToQueue((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(API(originalRequest));
      });
    });
  }

  if (status === 403) {
    toast('You are not authorized to perform this action.', 'error', {
      id: 'access-error',
      isClosable: false,
      duration: 10000,
    });
  } else if (status === 500) {
    toast('Something went wrong.', 'error', {
      id: 'unknown-error',
      isClosable: false,
      duration: 10000,
    });
  } else if (status === 404) {
    toast('The requested resource was not found.', 'error', {
      id: 'not-found',
      isClosable: false,
      duration: 10000,
    });
  } else if (data?.message === 'Token is invalid.') {
    toast('You have been logged out.', 'error', {
      id: 'logged-out',
      isClosable: false,
      duration: 5000,
    });

    logoutTimeout = logoutFn();
  }

  // Throw default error if no response is provided from the api (network issue, or api is potentially down)
  if (!status && !data?.message) {
    toast('The server is down. We are working on fixing the issue.', 'error', {
      id: 'network-error',
      isClosable: false,
      duration: 10000,
    });
  }

  return Promise.reject(err);
});

export default API;
