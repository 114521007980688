import DayPicker from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps as DayPickerProps } from 'react-day-picker/types/Props';
import { Input } from '@scope';

import { formatDateInput, formatFullDate, parseDateInput } from 'utils/date';

import 'react-day-picker/lib/style.css';

type DayPickerInputProps = DayPickerProps & {
  onChange: (value: string) => void;
  allowDateBeforeToday?: boolean;
  isInvalid?: boolean;
};

function DayPickerInput({
  onChange,
  allowDateBeforeToday,
  isInvalid,
  inputProps,
  ...props
}: DayPickerInputProps) {
  return (
    <DayPicker
      component={Input}
      onDayChange={(_value) => onChange(formatFullDate(_value))}
      formatDate={formatDateInput}
      parseDate={parseDateInput}
      inputProps={{
        readOnly: true,
        isInvalid,
        ...inputProps,
      }}
      dayPickerProps={{
        disabledDays: allowDateBeforeToday ? [] : [{ before: new Date() }],
        showOutsideDays: true,
      }}
      clickUnselectsDay
      {...props}
    />
  );
}

export default DayPickerInput;
