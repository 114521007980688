import {
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
} from '@chakra-ui/react';

const defaultStyles = {
  bg: 'ui.gray.light',
  colorScheme: 'secondary',
  h: '2',
};

type ProgressProps = ChakraProgressProps & {
  //
};

function Progress(props: ProgressProps) {
  return <ChakraProgress {...defaultStyles} {...props} />;
}

export default Progress;
