/* eslint-disable camelcase */
import { ChangeEvent, useEffect, useRef } from 'react';
import Script from 'next/script';
import { BsGeoAlt } from 'react-icons/bs';
import { Box, chakra, Flex, useOutsideClick } from '@chakra-ui/react';
import Input from '@scope/Input';
import Text from '@scope/Text';
import usePlacesAutocomplete from 'use-places-autocomplete';

import env from 'config/env';

const Geo = chakra(BsGeoAlt);

type GooglePlacesAutocompleteType = {
  name: string;
  placeholder?: string;
  onChange: (arg: string) => void;
  types?: string[];
  defaultValue?: string;
};

function GooglePlacesAutocomplete({
  name,
  placeholder = 'City, state or country',
  onChange,
  types,
  defaultValue,
}: GooglePlacesAutocompleteType) {
  const ref = useRef(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    requestOptions: {
      types,
    },
    debounce: 400,
  });

  useEffect(() => {
    if (!value.trim() && defaultValue) {
      setValue(defaultValue, false);
    }
  }, [value, setValue, defaultValue]);

  useOutsideClick({
    ref,
    handler: clearSuggestions,
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = (place) => {
    setValue(place, false);
    onChange(place);
    clearSuggestions();
  };

  const renderSuggestions = () => {
    return (
      <Box
        borderRadius="5px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="ui.gray.light"
      >
        {data.map((place) => {
          const {
            place_id,
            description,
            structured_formatting: { main_text, secondary_text },
          } = place;

          return (
            <Flex
              key={place_id}
              onClick={() => handleSelect(description)}
              align="center"
              p="2"
              cursor="pointer"
              _hover={{
                backgroundColor: 'ui.gray.light',
              }}
            >
              <Geo minW="4" w="4" h="4" color="ui.black.dark" mr="2" />

              <Text ms={0}>
                <Text ms={0} bold>
                  {main_text}
                </Text>{' '}
                {secondary_text}
              </Text>
            </Flex>
          );
        })}

        {/* eslint-disable-next-line @next/next/no-img-element */}
        <chakra.img
          ml="auto"
          mr="2"
          my="2"
          src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
          alt="Powered by Google"
        />
      </Box>
    );
  };

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap`}
      />

      <Box ref={ref}>
        <Input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleInput}
          isDisabled={!ready}
        />

        {status === 'OK' && renderSuggestions()}
      </Box>
    </>
  );
}

export default GooglePlacesAutocomplete;
