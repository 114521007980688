import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import { mediaQuery } from 'styles/breakpoints';

const useMobile = () => {
  const [isClient, setIsClient] = useState(false);

  const [isMobile] = useMediaQuery(mediaQuery.max.sm);

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isMobile: isClient ? isMobile : false,
    isClient,
  };
};

export default useMobile;
