import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  forwardRef,
} from '@chakra-ui/react';
import { RoundBox } from '@scope';

type CheckboxProps = ChakraCheckboxProps & {
  //
};

const RoundCheckbox = forwardRef<CheckboxProps, 'label'>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraCheckbox
        sx={{
          '.chakra-checkbox__control': {
            display: 'none',
          },
          '.chakra-checkbox__input:checked ~ .chakra-checkbox__label > div': {
            borderWidth: '2px',
            borderColor: 'ui.blue.primary',
          },
        }}
        ref={ref}
        {...props}
      >
        <RoundBox cursor="pointer">{children}</RoundBox>
      </ChakraCheckbox>
    );
  },
);

export default RoundCheckbox;
