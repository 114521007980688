import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

const SectionBox = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => (
    <Box
      borderRadius="5px"
      p={{ base: 4, sm: 6 }}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="ui.gray.light"
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  ),
);

export default SectionBox;
