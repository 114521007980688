/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import betaFeatureApi from 'api/betaFeature';
import { isAgency, isCustomer, isVendor } from './user';

export const filterByVendor = (user, featureId) => {
  // check to see if user of this group has access to any/this beta feature
  // if this featureID is a match with their user group, check if feature is limited only for certain users
  const featureIdMap = {};

  const vendorId = user.vendorProfile?.vendorId;
  if (user.vendorProfile?.vendor.betaFeatures?.length > 0) {
    user.vendorProfile.vendor.betaFeatures.map((feature) => {
      if (feature.betaFeatureId === featureId) {
        if (!feature.isUserSpecific) {
          if (feature.vendorId === vendorId) {
            featureIdMap[vendorId] = true;
          }
        } else if (
          feature.vendorId === vendorId &&
          feature.userId === user.id
        ) {
          featureIdMap[vendorId] = true;
        }
      }
    });
  }
  return Object.keys(featureIdMap).length > 0;
};
export const filterByAgency = (user, featureId) => {
  // check to see if user of this group has access to any/this beta feature
  // if this featureID is a match with their user group, check if feature is limited only for certain users
  const featureIdMap = {};
  const { agencyId } = user;
  if (user.agency?.betaFeatures?.length > 0) {
    user.agency.betaFeatures.map((feature) => {
      if (feature.betaFeatureId === featureId) {
        if (!feature.isUserSpecific) {
          if (feature.agencyId === agencyId) {
            featureIdMap[agencyId] = true;
          }
          if (feature.agencyId === agencyId && feature.userId === user.id) {
            featureIdMap[agencyId] = true;
          }
        }
      }
    });
  }
  return Object.keys(featureIdMap).length > 0;
};
export const filterByCustomer = (user, featureId) => {
  // check to see if user of this group has access to any/this beta feature
  // if this featureID is a match with their user group, check if feature is limited only for certain users
  const featureIdMap = {};
  const { customerGroupId } = user;
  if (user.customerGroup?.betaFeatures?.length > 0) {
    user.customerGroup.betaFeatures.map((feature) => {
      if (feature.betaFeatureId === featureId) {
        if (!feature.isUserSpecific) {
          if (feature.customerGroupId === customerGroupId) {
            featureIdMap[customerGroupId] = true;
          }
          if (
            feature.customerGroupId === customerGroupId &&
            feature.userId === user.id
          ) {
            featureIdMap[customerGroupId] = true;
          }
        }
      }
    });
  }
  return Object.keys(featureIdMap).length > 0;
};

export const checkBetaFeature = async (user, featureId) => {
  // check to see if feature has been deleted from beta_feature table
  // if it has, then all users should be able to access it
  const isFeatureInBeta = await betaFeatureApi.getFeatureById(featureId);
  if (!isFeatureInBeta) return true;
  if (isVendor(user?.role)) {
    const result = filterByVendor(user, featureId);
    return result;
  }
  if (isAgency(user?.role)) {
    const result = filterByAgency(user, featureId);
    return result;
  }
  if (isCustomer(user?.role)) {
    const result = filterByCustomer(user, featureId);
    return result;
  }
  return false;
};
