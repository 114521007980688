import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';

type RangeValue =
  | number
  | {
      min: number;
      max: number;
    };

type RangeProps = {
  step?: number;
  min?: number;
  max?: number;
  allowSameValues?: boolean;
  value: RangeValue;
  onChange: (value: RangeValue) => void;
};

const STEP = 1;
const MIN = 0;
const MAX = 100;

function Range({ step = STEP, min = MIN, max = MAX, ...props }: RangeProps) {
  return (
    <InputRange
      step={step}
      maxValue={max}
      minValue={min}
      formatLabel={() => ''}
      {...props}
    />
  );
}

export default Range;
