import { Flex, Spinner, SpinnerProps } from '@chakra-ui/react';
import { Spacer, Text } from '@scope';

type LoadingProps = SpinnerProps & {
  text?: string;
};

function Loading({
  text,
  size = 'lg',
  color = 'ui.black.medium',
  ...props
}: LoadingProps) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
      textAlign="center"
    >
      <Spinner size={size} color={color} {...props} />

      {text && (
        <>
          <Spacer h={2} />

          <Text ms={0} bold>
            {text}
          </Text>
        </>
      )}
    </Flex>
  );
}

export default Loading;
