import { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Text from '@scope/Text';

export type TextFieldProps = Omit<BoxProps, 'children'> & {
  label: string;
  content: string | ReactNode;
};

function TextField({ label, content, mb = '1', ...props }: TextFieldProps) {
  return (
    <Box {...props}>
      <Text ms={-1} bold block mb={mb}>
        {label}
      </Text>

      {typeof content === 'string' ? (
        <Text ms={0} color="ui.gray.dark">
          {content}
        </Text>
      ) : (
        content
      )}
    </Box>
  );
}

export default TextField;
