import {
  forwardRef,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';

import GetRemainingChars from 'components/shared/RemainingChars';

const defaultStyles: ChakraTextareaProps = {
  bg: 'ui.white',
  color: 'ui.black.medium',
  lineHeight: 'sm',
  fontWeight: 'normal',
  fontSize: 'sm',
  borderRadius: '5px',
  _placeholder: { color: 'ui.gray.medium' },
  p: '2',
  resize: 'none',
  rows: 4,
};

type TextareaProps = ChakraTextareaProps & {
  remainingChars?: {
    maxCharLength: number;
    input: any;
  };
};

const Textarea = forwardRef(
  ({ remainingChars, ...props }: TextareaProps, ref) => {
    return (
      <>
        <ChakraTextarea ref={ref} {...defaultStyles} {...props} />

        {remainingChars && remainingChars.input && (
          <GetRemainingChars
            maxCharLength={remainingChars.maxCharLength}
            inputLength={remainingChars.input.length}
          />
        )}
      </>
    );
  },
);

// Ref: https://github.com/chakra-ui/chakra-ui/issues/2269
Textarea.id = 'Textarea';

export default Textarea;
