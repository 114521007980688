import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react';

const FormControl = (props: ChakraFormControlProps) => {
  return <ChakraFormControl {...props} />;
};

export default FormControl;
