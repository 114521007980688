import { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import Text from './Text';

type RoundBoxProps = BoxProps & {
  isSelected?: boolean;
  onClick?: () => void;
  children: ReactNode;
  color?: string;
  ms?: number;
};

function RoundBox({
  isSelected,
  onClick,
  children,
  color = 'ui.black',
  ms = 0,
  ...props
}: RoundBoxProps) {
  return (
    <Box
      borderRadius="100px"
      background="ui.white"
      borderWidth={isSelected ? '2px' : '1px'}
      borderStyle="solid"
      borderColor={isSelected ? 'ui.blue.primary' : 'ui.gray.light'}
      m="1"
      p="10px 20px"
      cursor={onClick ? 'pointer' : 'initial'}
      onClick={onClick}
      userSelect="none"
      {...props}
    >
      <Text ms={ms} color={color}>
        {children}
      </Text>
    </Box>
  );
}

export default RoundBox;
