import Text from 'components/shared/Text';

const RemainingChars = ({ maxCharLength, inputLength }) => {
  if (!inputLength) return null;

  return (
    <Text fontSize="xs" color="ui.black.medium" float="right">
      {maxCharLength - inputLength} Characters Left
    </Text>
  );
};

export default RemainingChars;
