import NumberFormat, { NumberFormatProps } from 'react-number-format';
import Input from '@scope/Input';

type NumberInputProps = NumberFormatProps & {
  onChange: (value: string | number) => void;
  min?: number;
  max?: number;
  isCurrency?: boolean;
};

function NumberInput({
  onChange,
  min,
  max,
  isCurrency,
  ...props
}: NumberInputProps) {
  return (
    <NumberFormat
      customInput={Input}
      thousandSeparator
      decimalScale={0}
      prefix={isCurrency ? '$' : ''}
      onValueChange={({ floatValue }) => {
        onChange(floatValue);
      }}
      isAllowed={({ formattedValue, floatValue }) => {
        if (!floatValue) {
          return formattedValue === '';
        }

        if (min && max) {
          return floatValue >= min && floatValue <= max;
        }

        if (min) {
          return floatValue >= min;
        }

        if (max) {
          return floatValue <= max;
        }

        return true;
      }}
      {...props}
    />
  );
}

export default NumberInput;
