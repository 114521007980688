import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  useTheme,
} from '@chakra-ui/react';

type ButtonProps = ChakraButtonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, colorScheme = 'primary', ...props }, ref) => {
    const { fontSizes, fontWeights } = useTheme();

    const isLink = props.variant === 'link';
    const padding =
      props.leftIcon || props.rightIcon ? '10px 15px' : '10px 20px';

    return (
      <ChakraButton
        minWidth="auto"
        height="auto"
        padding={isLink ? '0' : padding}
        lineHeight="24px"
        borderRadius="5px"
        fontWeight={fontWeights.bold}
        fontSize={fontSizes.sm}
        colorScheme={colorScheme}
        borderWidth={isLink ? '0' : '1px'}
        borderStyle="solid"
        sx={{
          /* Center Icon */
          '.chakra-button__icon': {
            display: 'flex',
          },
        }}
        ref={ref}
        {...props}
      >
        {children}
      </ChakraButton>
    );
  },
);

export default Button;
