import API from 'config/axios';
import { CalendarProvider } from 'res/scheduler';

type CalendarData =
  | {
      provider: CalendarProvider;
      code: string;
      email?: never;
      password?: never;
    }
  | {
      provider?: never;
      code?: never;
      email: string;
      password: string;
    };

const onboard = async (agencyData) => {
  try {
    const { data } = await API.post('/agency-profile', agencyData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const updateProfile = async (agencyData, agencyId: string) => {
  try {
    const { data } = await API.patch(
      `/agency-profile/${agencyId}/update-profile`,
      agencyData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const removeMember = async (memberId: string) => {
  try {
    const { data } = await API.delete(`/agency/members/${memberId}`);

    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

const updateExpertRole = async (userId: string, role) => {
  try {
    const { data } = await API.patch(`/agency/members/${userId}/role`, {
      role,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const createCalendar = async (calendarData: CalendarData) => {
  try {
    const { data } = await API.post('/agency/calendar', calendarData);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const removeCalendar = async () => {
  try {
    const { data } = await API.delete('/agency/calendar');

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getSchedulingLink = async (agencyId) => {
  try {
    const { data } = await API.get(`/agency/${agencyId}/scheduling-link`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const requestAvailability = async (agencyId, vendorData) => {
  try {
    const { data } = await API.post(
      `/agency/${agencyId}/request-availability`,
      vendorData,
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const updateTags = async (agencyId: string, agencyData) => {
  try {
    const { data } = await API.post(
      `/agency/${agencyId}/update-tags`,
      agencyData,
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const agencyApi = {
  onboard,
  updateProfile,
  removeMember,
  updateExpertRole,
  createCalendar,
  removeCalendar,
  getSchedulingLink,
  requestAvailability,
  updateTags,
};

export default agencyApi;
