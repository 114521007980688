/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { USER_ROLE, USER_ROLES } from 'res/user';

export const isAgency = (role) => {
  return isAgencyAdmin(role) || isAgencyUser(role);
};

export const isAgencyUser = (role) => {
  return role === USER_ROLE.AGENCY_USER;
};

export const isAgencyAdmin = (role) => {
  return role === USER_ROLE.AGENCY_ADMIN;
};

export const isVendor = (role) => {
  return isVendorAdmin(role) || isVendorUser(role);
};

export const isVendorAdmin = (role) => {
  return role === USER_ROLE.VENDOR_ADMIN;
};

export const isVendorUser = (role) => {
  return role === USER_ROLE.VENDOR_USER;
};

export const isCustomer = (role) => {
  return isCustomerAdmin(role) || isCustomerUser(role);
};

export const isCustomerAdmin = (role) => {
  return role === USER_ROLE.CUSTOMER_ADMIN;
};

export const isCustomerUser = (role) => {
  return role === USER_ROLE.CUSTOMER_USER;
};

export const isProjectAssignee = (user, project) => {
  return isAgency(user.role) && user?.agency?.id === project?.assignee?.id;
};

export const isProjectVendor = (user, project) => {
  return (
    isVendor(user.role) &&
    user?.vendorProfile?.vendor?.id === project?.vendor?.id
  );
};

export const isSignedAgency = (user, project) => {
  return !!project.contracts?.[0]?.signs?.find(
    ({ signedBy }) => signedBy.agencyId === user.agencyId,
  );
};

export const isInBetaProgram = (user) => {
  return user?.isInBetaProgram;
};
