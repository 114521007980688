import API from 'config/axios';

const getFeatureById = async (id: string) => {
  try {
    const { data } = await API.get(`/betafeature/${id}`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getFeatureByGroups = async (id: string, groupIds) => {
  try {
    const { data } = await API.post(`/betafeature/${id}/groups`, {
      ...groupIds,
    });

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const betaFeatureApi = { getFeatureById, getFeatureByGroups };
export default betaFeatureApi;
