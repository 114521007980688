import { useState } from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { BsTelephonePlusFill } from 'react-icons/bs';
import { chakra } from '@chakra-ui/react';
import { Button, Loading, Modal, Text, toast } from '@scope';

import agencyApi from 'api/agency';
import projectApi from 'api/project';
import useCurrentUser from 'libs/useCurrentUser';
import { getUserFullName, isCustomer, isVendor } from 'utils/user';

const PhoneCustomer = chakra(BiPhoneCall);
const PhoneVendor = chakra(BsTelephonePlusFill);

type SchedulingLinkProps = {
  project?: Record<string, any> | null;
  agency?: Record<string, any> | null;
  isButton?: boolean;
};

function SchedulingLink({
  project = null,
  agency = null,
  isButton,
}: SchedulingLinkProps) {
  const { user } = useCurrentUser();

  const [isPending, setIsPending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [schedulingLink, setSchedulingLink] = useState(null);

  const agencyHasCalendar = project
    ? project.assignee?.calendar ||
      (project.opportunity?.experts[0]?.isAccepted &&
        project.opportunity?.experts[0]?.agency?.calendar)
    : agency.calendar;

  const isVendorScheduling = agency && !project && isVendor(user?.role);
  const isCustomerScheduling = project && !agency && isCustomer(user?.role);

  // If neither project nor agency are passed, throw error
  if (!project && !agency) {
    toast('Project or Agency required in SchedulingLink', 'error');
    return null;
  }
  // If criteria neither meets vendor scheduling nor customer scheduling, don't show component
  if (!(isVendorScheduling || isCustomerScheduling)) return null;
  // If agency calendar is not set, don't show component
  if (!agencyHasCalendar) return null;

  const goToScheduler = async () => {
    if (!schedulingLink) {
      if (isPending) return;

      setIsPending(true);

      try {
        if (project) {
          const link = await projectApi.getSchedulingLink(project.id);

          setSchedulingLink(
            `${link}?email=${user.email}&name=${getUserFullName(
              user,
            )}&project_id=${project.id}&prefilled_readonly=true`,
          );
        } else if (agency) {
          const link = await agencyApi.getSchedulingLink(agency.id);

          setSchedulingLink(
            `${link}?email=${user.email}&name=${getUserFullName(
              user,
            )}&project_id=${'Vendor call'}&prefilled_readonly=true`,
          );
        }

        setIsOpen(true);
      } catch (err) {
        toast(err.message, 'error');
      } finally {
        setIsPending(false);
      }
    } else {
      setIsOpen(true);
    }
  };

  const onClose = () => setIsOpen(false);

  const scheduleTextCustomer = 'Schedule a call with your expert';
  const scheduleTextVendor = 'Schedule a call with this agency';

  const buttonProps = isButton
    ? {
        isFullWidth: true,
        variant: 'outline',
        isLoading: isPending,
      }
    : isCustomer(user?.role)
    ? {
        // Style for customers scheduling calls with agency
        variant: 'link',
        ml: 'auto',
        pb: '16px',
        isDisabled: isPending,
      }
    : {
        // Style for vendors scheduling calls with agency
        variant: 'link',
        isDisabled: isPending,
      };

  return (
    <>
      <Button display="flex" onClick={goToScheduler} {...buttonProps}>
        {!isButton && isCustomer(user?.role) && (
          <Text ms={-1} bold color="ui.blue.primary" display="flex">
            <PhoneCustomer
              color="ui.black.dark"
              sx={{
                path: {
                  fill: 'currentColor',
                },
              }}
              fontSize="sm"
              mr="2"
            />{' '}
            {scheduleTextCustomer}
          </Text>
        )}

        {!isButton && isVendor(user?.role) && (
          <Text
            ms={-1}
            bold
            color="ui.blue.primary"
            display="flex"
            justifyContent="flex-end"
            w="full"
          >
            <PhoneVendor
              color="ui.black.dark"
              sx={{
                path: {
                  fill: 'currentColor',
                },
              }}
              fontSize="sm"
              mr="2"
            />{' '}
            {scheduleTextVendor}
          </Text>
        )}

        {isButton && scheduleTextCustomer}

        {!isButton && isPending && (
          <Loading color="ui.blue.primary" size="xs" ml="2" />
        )}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        contentHeight="100%"
        promptOnTabClose
        closeOnOverlayClick
        title="Schedule a call"
        footer={
          <Button
            onClick={onClose}
            mx="auto"
            w={{ base: '100%', sm: '60%', md: '40%', lg: '20%' }}
          >
            Close
          </Button>
        }
      >
        <iframe
          title="Scheduler"
          src={schedulingLink}
          width="100%"
          height="100%"
        />
      </Modal>
    </>
  );
}

export default SchedulingLink;
