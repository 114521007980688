import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cookies from 'js-cookie';
import { mixin } from 'lodash';

import useRequest from 'libs/useRequest';
import { USER_ROLES } from 'res/user';
import { checkBetaFeature } from 'utils/betaFeature';
import { isAgency, isAgencyAdmin, isAgencyUser } from 'utils/roles';

export type UseUserProps = {
  redirectIfFound?: string;
  allowedRoles?: USER_ROLES[];
  betaFeatureId?: string;
};

export const checkRoutePermissions = (
  router,
  user,
  allowedRoles,
  betaFeatureId,
) => {
  // If allowedRoles array doesn't contain user's role, redirect him to /404.
  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return '/404';
  }

  if (user && betaFeatureId) {
    const isEnabled = checkBetaFeature(user, betaFeatureId);
    if (!isEnabled) return '/';
  }

  if (isAgency(user.role)) {
    const isStripeRedirect = router.pathname === '/onboard/stripe/return';
    const isOnboard = router.pathname === '/onboard';
    const isExpertOnboarded = !!user.expertProfile;
    const isAgencyOnboarded = isExpertOnboarded && !!user.agency?.profile;

    // allow route to stripe onboarding redirect page
    if (isStripeRedirect && !isAgencyOnboarded) {
      return null;
    }

    if (!isExpertOnboarded && !isOnboard) {
      return '/onboard';
    }

    if (isAgencyUser(user.role)) {
      if (isExpertOnboarded && isOnboard) {
        return `/projects`;
      }
    }

    if (isAgencyAdmin(user.role)) {
      const isBusinessOnboard = router.pathname === '/onboard/business';

      if (!isAgencyOnboarded && !isBusinessOnboard && isExpertOnboarded) {
        return `/onboard/business`;
      }

      if (isAgencyOnboarded && (isOnboard || isBusinessOnboard)) {
        return '/opportunities';
      }
    }
  }

  return null;
};

function useCurrentUser({
  redirectIfFound,
  allowedRoles,
  betaFeatureId,
}: UseUserProps = {}) {
  const token = cookies.get('at');
  const router = useRouter();
  const {
    data: user,
    mutate: mutateUser,
    error,
  } = useRequest<any>(
    !!token && {
      url: '/user/me',
    },
  );

  // Set notAuthorizedRedirect to '/404' initially.
  let notAuthorizedRedirect: string | null = '/404';
  if (user) {
    // Run permission tests and set notAuthorizedRedirect to redirect link if user doesn't pass the tests, or to null.
    notAuthorizedRedirect = checkRoutePermissions(
      router,
      user,
      allowedRoles,
      betaFeatureId,
    );
  }

  useEffect(() => {
    // Don't attempt redirect user data is still fetching.
    if (user === undefined) return;
    // Redirect to notAuthorizedRedirect if user was found and he didn't pass the authorization tests.
    if (notAuthorizedRedirect) {
      router.push(notAuthorizedRedirect);
      return;
    }

    // Redirect only if no user found or if user is found and redirectIfFound is true.
    const shouldRedirect = !user || redirectIfFound;

    if (shouldRedirect) {
      router.push(redirectIfFound);
    }
  }, [user, redirectIfFound, allowedRoles, router, notAuthorizedRedirect]);

  if (!token) {
    return { user: null, error: null, mutateUser };
  }

  // Remove token and redirect to login on 401.
  // if (error?.['statusCode'] === 401) {
  //   cookies.remove('at', {
  //     path: '/',
  //   });
  //   cookies.remove('rt', {
  //     path: '/',
  //   });
  //   router.push('/login');
  // }

  return { user, error, mutateUser };
}

export default useCurrentUser;
