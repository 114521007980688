import { Box, forwardRef } from '@chakra-ui/react';

type SpacerProps =
  | {
      w: number;
      h: number;
    }
  | { w: number; h?: number }
  | { w?: number; h: number };

// 1 spacing unit is equal to 0.25rem, which translates to 4px by default in common browsers.
// Example: passing `w` or `h` as 6 will be turned into 24px.

const Spacer = forwardRef<SpacerProps, 'div'>(({ w, h }, ref) => {
  return <Box w={w} h={h} ref={ref} />;
});

export default Spacer;
