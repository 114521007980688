import { BsCheckCircle, BsExclamationCircle } from 'react-icons/bs';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  chakra,
  createStandaloneToast,
  Flex,
  UseToastOptions,
} from '@chakra-ui/react';

import theme from 'styles/theme';

const CheckIcon = chakra(BsCheckCircle);
const WarningIcon = chakra(BsExclamationCircle);

const chakraToast = createStandaloneToast({ theme });

const toast = (
  title: string,
  status: UseToastOptions['status'],
  options: UseToastOptions = {
    isClosable: true,
  },
) => {
  if (!chakraToast.isActive(options.id)) {
    chakraToast({
      duration: 9000,
      position: 'top-right',
      render: ({ id }) => (
        <Flex
          borderRadius={5}
          border="1px"
          borderColor="ui.blue.primary"
          color="ui.black.bg"
          justify="space-between"
          p={2}
          width="fit-content"
          bg="ui.gray.bg"
          height="auto"
          marginLeft="auto"
          marginRight="5"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        >
          <Box mt="0.5">{renderIcon(status)}</Box>
          <Box mx="2.5" fontSize="xs" lineHeight="xs" fontWeight="bold">
            {title}
          </Box>

          {options.isClosable && (
            <Box
              onClick={() => chakraToast.close(id)}
              lineHeight="0"
              cursor="pointer"
              mt="1"
            >
              <CloseIcon w="3" h="3" color="ui.gray.dark" />
            </Box>
          )}
        </Flex>
      ),
      ...options,
    });
  }
};

const renderIcon = (status: string) => {
  switch (status) {
    case 'success':
      return <CheckIcon w="4" h="4" color="ui.gray.dark" />;
    case 'error':
      return <WarningIcon w="4" h="4" color="ui.gray.dark" />;
    default:
      return <InfoIcon w="4" h="4" color="ui.gray.dark" />;
  }
};

export default toast;
