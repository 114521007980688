import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  forwardRef,
} from '@chakra-ui/react';

type CheckboxProps = ChakraCheckboxProps & {
  //
};

const Checkbox = forwardRef<CheckboxProps, 'label'>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraCheckbox
        colorScheme="primary"
        size="lg"
        lineHeight="xs"
        alignItems="center"
        ml="1"
        spacing="2"
        ref={ref}
        {...props}
        sx={{
          '.chakra-checkbox__control': {
            w: '6',
            h: '6',
            mt: '2',
          },
          '.chakra-checkbox__label': {
            mt: '1',
          },
          ...props.sx,
        }}
      >
        {children}
      </ChakraCheckbox>
    );
  },
);

export default Checkbox;
