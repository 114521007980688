import { ReactNode } from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
} from '@chakra-ui/react';
import Text from '@scope/Text';

export type Tab = {
  label: string;
  content: ReactNode;
  isHidden?: boolean;
  sections?: ReactNode;
};

type TabsProps = Omit<ChakraTabsProps, 'children'> & {
  tabs: Tab[];
};

function Tabs({ variant = 'line', ...props }: TabsProps) {
  const tabs = props.tabs.filter((tab) => !tab.isHidden);

  return (
    <ChakraTabs
      colorScheme="ui.black.medium"
      size="sm"
      variant={variant}
      {...props}
    >
      <TabList
        experimental_spaceX="6"
        overflowX="auto"
        borderBottomWidth={{ base: '1px', sm: 'none' }}
        borderBottomStyle={{ base: 'solid', sm: 'none' }}
        borderBottomColor={{ base: 'ui.gray.light', sm: 'none' }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            pt="0"
            px="4"
            pb="2"
            mb="0"
            ml={index === 0 ? '2' : '0'}
            mr={index + 1 === tabs.length ? '2' : '0'}
            borderBottomWidth="2px"
          >
            <Text
              ms={0}
              color={
                props.index === undefined || props.index === index
                  ? 'ui.black.medium'
                  : 'ui.gray.dark'
              }
            >
              {tab.label}
            </Text>
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.label} p="0">
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
}

export default Tabs;
