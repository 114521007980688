import { Box, Flex } from '@chakra-ui/react';

import useMobile from 'libs/useMobile';
import Spacer from './Spacer';
import Text from './Text';

type PageHeadingProps = {
  title: string | JSX.Element;
  description?: string;
  bg?: string;
  rightComponent?: JSX.Element | null;
  width?: string;
};

const PageHeading = ({
  title,
  description,
  bg = 'transparent',
  rightComponent,
  width = '100%',
}: PageHeadingProps) => {
  const { isMobile } = useMobile();

  return (
    <Flex
      flexDir={{ base: 'column', sm: 'row' }}
      align={{ sm: 'center' }}
      justify={{ sm: 'space-between' }}
      bg={bg}
      w={width}
      mb={{ base: '4', sm: '0' }}
    >
      <Box>
        {isMobile && rightComponent ? (
          <Flex justifyContent="flex-end">{rightComponent}</Flex>
        ) : null}
        <Flex align="center">
          {typeof title === 'string' ? (
            <Text asEl="h1" ms={isMobile ? 1 : 2} bold>
              {title}
            </Text>
          ) : (
            title
          )}
        </Flex>

        {description && (
          <>
            <Spacer h={1} />
            <Text asEl="h2" ms={isMobile ? -1 : 0} color="ui.gray.dark">
              {description}
            </Text>
          </>
        )}
      </Box>

      {!isMobile && rightComponent && rightComponent}
    </Flex>
  );
};

export default PageHeading;
